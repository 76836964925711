import React from 'react'
import { Helmet } from 'react-helmet'
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios"
import ToastServive from 'react-material-toast'

import CartContext from "../context/CartConext"
import s from "./index.module.scss"
import Layout from '../components/layouts'



const RenderBody = () => {
  const { items, removeItem } = React.useContext(CartContext)

  async function checkout() {
    const stripe = await loadStripe(process.env.GATSBY_STRIPE_KEY);

    try {

      const { data } = await axios.post("/.netlify/functions/stripe-cart", {
        items,
        success_url: window.location.origin + '/thank-you',
        cancel_url: window.location.href
      })

      await stripe.redirectToCheckout({
        sessionId: data.session.id
      })

    } catch (err) {
      console.error(err)
      const toast = ToastServive.new({
        place:'topRight',
        duration:3,
        maxCount:8
      });
      toast.error(err.message)
    }

  }

  return (
    <React.Fragment>
  
      <div className={s.cartContainer}>

        <h1 className="text-center mb-5 mt-5">
          Cart
        </h1>

        <section className="border-top mb-5">
          {items.map((item, index) => (<div className="py-2 border-bottom d-flex justify-content-between" key={index}>
            <div className="d-flex">
              <div>
                <img className="rounded" style={{ maxWidth: 60 }} src={item.images[0]} alt={`Product item: ${item.name}`} />
              </div>
              <div>
                <h6>{item.name}</h6>
                <p>{item.amount / 100}$ - {item.description}</p>
              </div>
            </div>
            <div>
              <button className="btn btn-link btn-sm" onClick={() => removeItem(index)}>Remove</button>
            </div>
          </div>))}

          <div className="h3 mt-3">Total: {items.reduce((total, item) => { return total + item.amount / 100}, 0).toFixed(2)}$</div>

          <button onClick={checkout} className="btn btn-outline-success rounded-pill mt-4 mb-4" disabled={!items.length}>Proceed to checkout</button>
        </section>

  
      </div>
  
    </React.Fragment>
  )
}

const Product = props => {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cart</title>
      </Helmet>
      <RenderBody />
    </Layout>
  )
}

export default Product